import React from 'react';
import './Footer.css';
import { Button } from '../../Button';
import { Link } from 'react-router-dom';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin
} from 'react-icons/fa';
import { MdFingerprint } from 'react-icons/md';
import logo from './logo.png';

function Footer() {
  return (
    <div className='footer-container'>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>About Us</h2>
            <a href="https://www.freeprivacypolicy.com/live/2354a892-0890-46b2-baf0-1e41062d9faa" target="blank"> Privacy Policy</a>
            <a href="https://www.freeprivacypolicy.com/live/60b3e590-b4f1-4fc5-8609-114bf7c41a0f" target="blank"> Terms of Service</a>
          </div>
          <div className='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='/sign-up'>Contact</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Social Media</h2>
            <a href="https://www.linkedin.com/in/sarthak-dhawan-5796901ab/" target="blank"> Linkedin</a>
            <a href="https://www.youtube.com/channel/UCsa2-9KCTKBvOy_R1imtTgQ?view_as=subscriber" target="blank"> Youtube</a>
          </div>
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
            <img className='logo-png' src={logo} width={"50"} height="50"/>
              GRADIFY
            </Link>
          </div>
          <small className='website-rights'>GRADIFY © 2020</small>
          <div className='social-icons'>
            <Link
              className='social-icon-link'
              to={
                '//www.youtube.com/channel/UCsa2-9KCTKBvOy_R1imtTgQ/videos?view_as=subscriber'
              }
              target='_blank'
              aria-label='Youtube'
            >
              <FaYoutube />
            </Link>
            <Link
              className='social-icon-link'
              to='//www.linkedin.com/in/sarthak-dhawan-5796901ab/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <FaLinkedin />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
