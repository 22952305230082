export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'the new way to check grades',
  headline: 'Fast. Easy. Free.',
  description:
    'Gradify allows students in Frisco ISD to easily and quickly check their grades. It offers a multitude of unique features, and is 100% free.',
  buttonLabel: 'badges',
  imgStart: '',
  img: 'images/home.png',
  alt: 'Credit Card'
};

export const homeObjTwo = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'accommodates all your needs',
  headline: 'Check your Schedule.',
  description:
    'Gradify allows you to view your class schedule in a simple and intuitive, splitting courses by day and semester. ',
  buttonLabel: '',
  imgStart: 'start',
  img: 'images/schedule.png',
  alt: 'Vault'
};

export const homeObjThree = { 
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: '100% SECURE',
  headline: 'Secure by design.',
  description:
    "Gradify communicates directly with a secure server. This means that your information is never stored for longer than 10 minutes. Our first priority is ensuring that your information is kept safe.",
    buttonLabel: '',
    imgStart: 'start',
  img: 'images/svg-4.svg',
  alt: 'Vault'
};

export const homeObjFour = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'accommodates all your needs',
  headline: 'Analyze your GPA',
  description:
    'Gradify calculates both your weighted and unweighted GPA while also allowing for you to customize GPA scales and exclusions. ',
  buttonLabel: '',
  imgStart: 'start',
  img: 'images/gpa.png',
  alt: 'Vault'
};

export const homeObjFive = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Stay updated',
  headline: 'Check Official Transcript.',
  description:
    'You can access your official Frisco ISD transcript from Gradify, and easily see how you\'ve performed over time. ',
  buttonLabel: '',
  imgStart: 'start',
  img: 'images/transcript.png',
  alt: 'Vault'
};

export const homeObjSix = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Stay prepared',
  headline: 'Calculate future grades.',
  description:
    'Use Gradify\'s built in what-if calculator to see how your grade will change. Users can now prioritize classes and assignments accordingly.',
    buttonLabel: '',
    imgStart: '',
  img: 'images/whatif.png',
  alt: 'Vault'
};
export const homeObjSeven = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'stay connected',
  headline: 'Latest Account Info.',
  description:
    'View your latest HAC info and stay up to date on the most recent changes. ',
    buttonLabel: '',
    imgStart: '',
  img: 'images/settings.png',
  alt: 'Vault'
};
export const homeObjEight = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Plan ahead',
  headline: 'View Course Requests.',
  description:
    'View your official course requests for the following year to easily plan and visualize class work load and total credits earned. ',
    buttonLabel: '',
    imgStart: '',
  img: 'images/courseRequests.png',
  alt: 'Vault'
};
export const homeObjNine = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'stay informed',
  headline: 'Search All Courses.',
  description:
    'Search through 300+ courses that FriscoISD has to offer. View detailed course description and credit information. Uses a dynamic search engine to sort by name, description, and other relevant course information.',
  buttonLabel: '',
  imgStart: 'start',
  img: 'images/courseCatalog.png',
  alt: 'Vault'
};
export const homeObjTen = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Personalize your experience',
  headline: 'Customize Color Scheme.',
  description:
    'Customize the app by choosing between 15 different color schemes.',
    buttonLabel: '',
    imgStart: '',
  img: 'images/colorCustomize.png',
  alt: 'Vault'
};